var user = {
	alert: function (message) {
		var icon = 'warning circle icon';

		if(typeof message != 'string') {
			var settings = message;
			var message = settings.message;
			var content = settings.content;
			var submit = settings.submit;
			icon = settings.icon || 'warning circle icon';
		}

		var $modal = $('.ui.modal.force');

		// Set the icon
		$modal.find('.header .icon')[0].className = icon;

		// Set the header message
		$modal.find('.header .message').html(message);

		$modal.find('.ui.ok').text('OK');
		$modal.find('.ui.cancel').hide();

		// Remove the content if undefined
		if(typeof content == 'undefined') {
			$modal.find('.content').hide();
		}
		// Update the content
		else {
			$modal.find('.content').html(content);
		}

		$modal
			.modal({
				closable  : true,
				// onDeny    : deny,
				// onApprove : approve
			})
			.modal('show');

	},
	confirm: function (message, approve, deny) {
		if(typeof deny == 'undefined') {
			deny = function(){
				$('.ui.modal.force').modal('hide');
				return false;
			}
		}

		if(typeof message != 'string') {
			var settings = message;
			var message = settings.message;
			var content = settings.content;
			var submit = settings.submit;
			var icon = settings.icon || 'help circle outline icon';
		}

		var $modal = $('.ui.modal.force');

		// Set the icon
		$modal.find('.header .icon')[0].className = icon;

		// Set the header message
		$modal.find('.header .message').html(message);

		// Remove the content if undefined
		if(typeof content == 'undefined') {
			$modal.find('.content').hide();
		}
		// Update the content
		else {
			$modal.find('.content').html(content);
		}

		$modal
			.modal({
				closable  : false,
				onDeny    : deny,
				onApprove : approve
			})
			.modal('show');
	}
}

jQuery( function($) {
	$('.ui.dropdown')
		.dropdown();

	$('.button')
		.popup();

	$('.ui.progress')
		.progress();

	$('.menu .item')
		.tab();

	$('.action.select-clear').parents('.field').find('.ui.dropdown').dropdown('restore defaults');

	$('a.action.confirm').on('click', function(e){
		var formId = this.dataset.formId;
		var message = this.dataset.confirm || 'Are you sure?';
		var dialog = '';
		var submit = false;
		var action = this.dataset.href;

		e.preventDefault();

		if(this.dataset.dialog) {
			dialog = $(this.dataset.dialog).clone(true, true);
			dialog.find('form').addBack('form').attr('action', action);
			submit = dialog.attr('action') || false
		}

		user.confirm({
			// icon: 'user icon',
			submit: submit,
			content: $('<div>').append(dialog).html(),
			message: message,
		},
		function() {
			var $modal = $('.ui.modal.force');
			if(submit) {
				$modal.find('form').trigger('submit');
			}
			else {
				$('#' + formId).trigger('submit');
			}
		});

		return false;
	});

	$('a.action').on('click', function(e){
		e.preventDefault();

		if(typeof this.dataset.confirm == 'undefined') {
			$('#' + this.dataset.formId).trigger('submit');
		}
	});

	$('a.alert').on('click', function(e) {
		e.preventDefault();

		if(typeof this.dataset.alert !== 'undefined') {
			user.alert(this.dataset.alert);
		}
	});

	$('.ui.sidebar').first()
		.sidebar('attach events', '#sidebar-toggle');

	// Single date picker
	$('input.datepicker').daterangepicker({
		'singleDatePicker' : true,
		'format': 'MMM D, YYYY'
	});

	$('input.datepicker-custom').each(function(){
		$(this).daterangepicker({
			'singleDatePicker' : true,
			'format': 'MMM D, YYYY',
			'minDate': moment(this.dataset.minDate, "YYYY-MM-DD"),
			'maxDate': moment(this.dataset.maxDate, "YYYY-MM-DD"),
		});
	});

	//Remove semantic ui datepicker error highlighting
	$('input.datepicker, input.datepicker-custom').on('change', function(e) {
		if (this.value) {
			$(this).closest('.field').removeClass('error');
		}
	});

	$('.ui.range').each(function(index, item){
		var	valueField = '#' + $(item).attr('data-value-field-id');
		$(item).range({
			min: 0,
			max: 100,
			start: $(valueField).val(),
			step: 5,
			input: valueField,
			onChange: function(val) {
				var	labelField = this.input.slice(1) + '-label';
				document.getElementById(labelField).innerHTML = val;
			}
		})
	});



    // Default to last 30 days for the date range picker
	var rangeStart = moment().subtract(29, 'days');
    var rangeEnd = moment();

    $('input.daterange').daterangepicker({
        startDate: rangeStart,
        endDate: rangeEnd,
        ranges: {
           'Today': [moment(), moment()],
           'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Last 7 Days': [moment().subtract(6, 'days'), moment()],
           'Last 30 Days': [moment().subtract(29, 'days'), moment()],
           'This Month': [moment().startOf('month'), moment().endOf('month')],
           'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    });

    // Bit of a dumb solution but I spent too much time already trying to get the better solution to work.
	$('.clear-form').on('click', function(e) {
		if(window.location.toString().indexOf('?') != -1) {
			window.location = window.location.toString().split('?')[0];
		}

		// $(this).parents('form').find('input, textarea, select').each(function(index, item) {
		// 	if(item.nodeName.toLowerCase() == 'select') {
		// 		item.selectedIndex = 0;
		// 		$(item).trigger('change');
		// 		$(this).parents('form').find('.ui.dropdown').trigger('change')
		// 	}
		// 	else if(item.nodeName.toLowerCase() == 'input') {
		// 		if(['reset', 'submit', 'button'].indexOf(item.type) == -1) {
		// 			item.value = '';
		// 			item.setAttribute('value', '');
		// 		}
		// 	}
		// });
	});

	$('#company-incorporated').on('change', function() {
		switch($(this).val()) {
			case 'false':
			case 'not_incorporated':
				user.alert('You must be incorporated to participate in HealthPlus. For more information about this please call our office at 613-226-1964 or email info@healthplusplan.ca');
				break;

			case 'partnership':
			case 'sole_proprietor':
				user.alert('Sole Proprietors and Partnerships only qualify for HealthPlus if more than one employee is being enrolled and at least one of those employees is not related by blood or marriage to the owner/partners. Due to Canada Revenue Agency regulations, HealthPlus does not qualify as a PHSP and its favorable tax treatment if only the company owners/partners and their families are enrolled.');
				break;

			case 'true':
			case 'incorporated':
			default:
				break;
		}
	});

	//Store 'old' values for when the carryover/prorate get automatically unchecked if no limit is chosen
	var carryover = $('#enable-carryover').prop('checked');
	var prorate = $('#prorate-employee').prop('checked');

	//Toggle the limited fields (carryover and proration options)
	$('#employee-nolimit').on('change', function() {
		//Handle limited fields when no limit is checked
		if ($('#employee-nolimit').prop('checked')) {
			$('#limited-fields').slideUp();
			//Uncheck the options, if they are checked
			$('#prorate-employee, #enable-carryover').prop("checked", false);
			$('#proration-fields').hide();
		}
		//Handle limited fields when no limit is unchecked
		else {
			$('#limited-fields').slideDown();
			$('#enable-carryover').prop('checked', carryover);
			$('#prorate-employee').prop('checked', prorate);

			if (prorate) {
				$('#proration-fields').slideDown();
			}
		}
	});

	//Toggle the proration fields
	$('#prorate-employee').on('change', function() {
		calculateProratedLimit();
		$('#proration-fields').slideToggle();
		prorate = $('#prorate-employee').prop('checked');
	});

	$('#enable-carryover').on('change', function() {
		carryover = $('#enable-carryover').prop('checked');
	});

	//Show preview of the prorated limit, if in use
	$('input.prorate').on('keyup', calculateProratedLimit);
	$('.ui.dropdown.prorate').dropdown('setting', 'onChange', calculateProratedLimit);

	function calculateProratedLimit() {
		var start = $('#benefit-start-date').val();
		var end = $('#fiscal-end').val();
		var calcType = $('#proration-calculation-type').val();
		var round = $('#proration-rounding').val();
		var annualLimit = $('#employee-annual-limit').val();

		//If missing calc values, set amount to 0
		if (!start || !calcType || !round || !annualLimit || !end || annualLimit == 0) {
			$('#prorated-limit').html('0');
			$('#invalid-proration').fadeIn();
			return;
		}
		else {
			$('#invalid-proration').fadeOut();
		}

		//Figure out the difference in months (weird calculation to match Carbon)
		var diff = moment(end, 'YYYY-MM-DD').diff(moment(start, 'MMM D, YYYY'), 'days', true) / 365 * 12;

		//Round up/down, depending on rounding type and monthly vs quarterly
		var multiplier = calcType == 'monthly' ? 1 : 3;

		//Calculate the number of months to use for prorating
		diff = round == 'up' ? Math.ceil(diff/multiplier)*multiplier : Math.floor(diff/multiplier)*multiplier;

		//Calculate the prorated amount (annual limit * months of proration / 12 months per year)
		var proratedLimit = Math.round(annualLimit*diff/12);

		//Update the field
		$('#prorated-limit').html(proratedLimit);
	}

	//Catch the Reseller create form submission and make users confirm that the token is correct
	$('form#create-reseller').on('submit', function(e) {
		var message = 'Are you sure you want to create a reseller at this subdomain?</br><br/>';
		message += $('#token').val().toLowerCase() + $('#base-url').html() + '<br/><br/>';
		message += 'Reseller tokens cannot be changed once they have been created. This URL is final.';
		var dialog = '';

		e.preventDefault();

		user.confirm({
			// icon: 'user icon',
			content: $('<div>').append(dialog).html(),
			message: message,
		},
		function() {
			//Remove listener from the form and the submit the form
			$('form#create-reseller').off('submit').trigger('submit');
		});

		return false;
	});

	//Toggle the invoice checkboxes
	var checkChecks = true;

	$('.toggle-checkboxes').click(function(e) {
		$(this).parents('table').find('input[type="checkbox"]').each(function() {
			$(this).prop('checked', checkChecks);
		});

		checkChecks = !checkChecks;
	});

	$('.checkbox-rows tbody tr').on('click', function(e) {
		if(e.target.nodeName === 'TD') {
			var input = $(this).find('td:first-child input');
			input.prop('checked', !input.prop('checked'));
		}
	});

	$('.set-cancel-to-back').click(function(e) {
		$('.ui.modal .cancel.button .message').html('Back');
	});

	/**
	 * Do more work to make sure employers super approve claims that go over the annual limit
	 */
	$('.employer-claim-approval input.amount_input').blur(function(e) {
		checkClaimOverage(this);
	});

	//If they submit the form and the amount is over and they haven't acknowledge, stahp
	$('.employer-claim-approval').submit(function(e) {
		var el = $(this).find('input.amount_input');
		var over = checkClaimOverage(el);

		//If there's an overage, highlight the acknowledgement checkbox if it's not checked
		if (over && !$(this).find('input:checked').length) {
			e.preventDefault();

			$(this).find('.overage-field').addClass('error');
		}
	});

	/**
	 * Check if the claim approved amount will result in an overage
	 * Show/hide and require the approval checkbox accordingly
	 *
	 * @param  element el
	 *
	 * @return bool  True if there is an overage
	 */
	function checkClaimOverage(el) {
		//When the amount changes, check to see if this goes over the balance
		var amount = $(el).val();
		var balance = $(el).parents('form').data('available-balance');

		//If the amount is less than 0, err
		if (amount <= 0) {
			$(el).val('0.00');

			return false;
		}

		//If it does, reveal the checkbox and make them acknowledge
		var $overage = $(el).parents('form').find('.overage-field');

		if (amount > balance) {
			//Update the amounts in the checkbox field
			$overage.find('.approved_amount').html(amount);
			$overage.find('.overage_amount').html((balance - amount) * -1);

			//Show the checkbox field
			$overage.slideDown();

			//Make it required
			$overage.find('input').attr('required', 'required');

			//Return true for over
			return true;
		}
		//Otherwise it is not required
		else {
			$overage.slideUp();

			//Make it not required and remove errors
			$overage.find('input').attr('required', false);
			$overage.removeClass('error');
		}

		return false;
	}
});
